import React, {useEffect, useState} from "react";
import {
  FinishWebsiteReviewRequest,
  FinishWebsiteReviewResponse,
  GetPendingWebsiteReviewsRequest,
  GetPendingWebsiteReviewsResponse,
  IAdminBusinessProfileProto,
  space
} from "../../../compiled";
import {SendRpc} from "../../../rpcSender";
import {useAuth0} from "@auth0/auth0-react";
import {getBookingHost} from "../../HostnameUtils";
import ApprovalStatus = space.kenko.proto.ApprovalStatus;

/**
 * For reviewing business profiles.
 */
export const WebsiteReviewsTab = () => {

  const {getIdTokenClaims} = useAuth0();

  const [reviews, setReviews] = useState<IAdminBusinessProfileProto[]>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    GetPendingWebsiteReviews()
  }, [])

  const GetPendingWebsiteReviews = () => {
    const request = GetPendingWebsiteReviewsRequest.encode(new GetPendingWebsiteReviewsRequest()).finish();

    setError(undefined);
    SendRpc(getIdTokenClaims, 'get_pending_reviews', request).then(r => {
      let response = GetPendingWebsiteReviewsResponse.decode(r);

      if (!response.okay) {
        setError(response.error ? response.error : 'Unknown rpc error')
        return;
      }

      setReviews(response.profiles);

    }).catch(e => {
      console.log(e);
      setError(e);
    });
  }

  const FinishWebsiteReview = (profile: IAdminBusinessProfileProto, status: ApprovalStatus, message: string) => {
    const request = FinishWebsiteReviewRequest.encode(new FinishWebsiteReviewRequest({
          businessId: profile.businessId,
          version: profile.versionId,
          reviewMessage: message,
          status: status,
        }
    )).finish();

    setError(undefined);
    SendRpc(getIdTokenClaims, 'finish_review', request).then(r => {
      let response = FinishWebsiteReviewResponse.decode(r);

      if (!response.okay) {
        setError('RPC Error finishing review');
        return;
      }

      if (!response.updated) {
        setError('Error, no updated profile');
        return;
      }
      
      // Update this single review in place. 
      // @ts-ignore
      setReviews(existingReviews =>
          existingReviews?.map(existing =>
              ((existing.businessId == profile.businessId) && (existing.reviewTimestamp == profile.reviewTimestamp)) ?
                  response.updated : existing
          )
      );

    }).catch(e => {
      console.log(e);
      setError('Error: ' + e);
    });
  }


  return (
      <>
        <div>
          <b>Review dashboard</b>
          <br/>
        </div>

        <table>
          <thead>
          <tr>
            <th>
              Business name
            </th>
            <th>
              Review status
            </th>
            <th>
              Submission Time
            </th>
            <th>
              Short URL
            </th>
            <th>
              Website preview
            </th>
            <th>
              Approve
            </th>
            <th>
              Deny
            </th>
          </tr>
          </thead>
          <tbody>

          {reviews?.map(review => {
            return <tr>
              <td>{review.proto?.businessName}</td>
              <td>{review.review?.status && ApprovalStatus[review.review.status]}</td>
              <td>{review.reviewTimestamp ? new Date(review.reviewTimestamp as number).toLocaleString() : ''}</td>
              <td>{review.proto?.shortUrl}</td>
              <td><a target='_blank'
                     href={`${getBookingHost()}/p/${review.proto?.shortUrl || 'NOTFOUND'}?v=${review.versionId}&b=${review.businessId}`}>Link</a>
              </td>
              <td><button onClick={() => FinishWebsiteReview(review, ApprovalStatus.APPROVED, "")}>Approve</button></td>
              <td><button onClick={() => FinishWebsiteReview(review, ApprovalStatus.REJECTED, "Your submission had issues.")}>Reject</button></td>
            </tr>
          })}
          </tbody>
        </table>

        {reviews?.length == 0 && <div style={{textAlign: 'center'}}>None found</div>}

        <div>
          {error && <div style={{color: 'red'}}>{error}</div>}
        </div>
      </>
  );
}
