import React, {useEffect, useState} from "react";
import {
  AdminBusinessProto,
  ApproveBusinessRequest,
  ApproveBusinessResponse,
  GetPendingBusinessApprovalsRequest,
  GetPendingBusinessApprovalsResponse,
  IAdminBusinessProto, IProviderReviewInfo,
  space
} from "../../../compiled";
import {SendRpc} from "../../../rpcSender";
import {useAuth0} from "@auth0/auth0-react";
import ProviderApprovalState = space.kenko.proto.ProviderApprovalState;

/**
 * For reviewing business profiles.
 */
export const ProviderReviewsTab = () => {

  const {getIdTokenClaims} = useAuth0();

  const [businesses, setBusinesses] = useState<IProviderReviewInfo[]>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    GetPendingBusinessApprovals()
  }, [])

  const GetPendingBusinessApprovals = () => {
    const request = GetPendingBusinessApprovalsRequest.encode(
        new GetPendingBusinessApprovalsRequest()).finish();

    setError(undefined);
    SendRpc(getIdTokenClaims, 'get_pending_provider_approvals', request).then(r => {
      let response = GetPendingBusinessApprovalsResponse.decode(r);

      if (!response.okay) {
        setError(response.error ? response.error : 'Unknown rpc error')
        return;
      }

      setBusinesses(response.business);

    }).catch(e => {
      console.log(e);
      setError(e);
    });
  }

  const finishReview = (profile: IProviderReviewInfo, status: ProviderApprovalState) => {
    const request = ApproveBusinessRequest.encode(new ApproveBusinessRequest({
          businessId: profile.business?.businessId,
          status: status,
        }
    )).finish();

    setError(undefined);
    SendRpc(getIdTokenClaims, 'approve_business', request).then(r => {
      let response = ApproveBusinessResponse.decode(r);

      if (!response.okay) {
        setError('RPC Error finishing review');
        return;
      }

      if (!response.updated) {
        setError('Error, no updated profile');
        return;
      }

      // Update this single review in place. 
      // @ts-ignore
      setBusinesses(existingReviews =>
          existingReviews?.map(existing =>
              (existing.business?.businessId == profile.business?.businessId) ?
                  {...existing, business: response.updated} :
                  existing)
      );

    }).catch(e => {
      console.log(e);
      setError('Error: ' + e);
    });
  }


  return (
      <>
        <div>
          <b>Provider Reviews</b>
          <br/>
        </div>

        <table style={{borderSpacing: 10}}>
          <thead>
          <tr>
            <th>
              Business ID
            </th>
            <th>
              Status
            </th>
            <th>
              Submission Time
            </th>
            <th>
              Survey response
            </th>
            <th>
              User
            </th>
            <th>
              Approve/Deny
            </th>
          </tr>
          </thead>
          <tbody>

          {businesses?.map(review => {
            console.log(review);

            const business = review.business as AdminBusinessProto;
            const provider = review.provider;
            
            return <tr>
              <td>{business.businessId}</td>
              <td>{ProviderApprovalState[business.approvalState as number].toString()}</td>
              <td>{business.approvalReviewTimestamp ? new Date(business.approvalReviewTimestamp as number).toLocaleString() : ''}</td>
              <td><pre>{JSON.stringify(business.surveyProto, null, 2)}</pre></td>
              <td>
                <td><pre>{JSON.stringify(provider, null, 2)}</pre></td>
              </td>
              <td>
                <button onClick={() => finishReview(review, ProviderApprovalState.PROVIDER_APPROVED)}>Approve</button>
                <br/><br/>
                <button onClick={() => finishReview(review, ProviderApprovalState.PROVIDER_REJECTED)}>Reject</button>
              </td>
            </tr>
          })}
          </tbody>
        </table>

        {businesses?.length == 0 && <div style={{textAlign: 'center'}}>None found</div>}

        <div>
          {error && <div style={{color: 'red'}}>{error}</div>}
        </div>
      </>
  );
}
